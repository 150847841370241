import { apiSaleReports } from '@/api/'

export default {
  namespaced: true,
  // state: {},
  // getters: {},
  // mutations: {},
  actions: {
    fetchSaleReportsF2(ctx, { agencyId, params }) {
      return apiSaleReports.fetchSaleReportsF2(agencyId, params)
    },

    exportSaleReportsF2(ctx, { agencyId, payload }) {
      return apiSaleReports.exportSaleReportsF2(agencyId, payload)
    },
  },
}
