var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "sale-report-index"
    }
  }, [_vm.roleMama ? _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('saleReport.title')))])];
      },
      proxy: true
    }], null, false, 677466017)
  }, [_c('SaleReportList')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('saleReport.f2.title')))])];
      },
      proxy: true
    }], null, false, 4054399259)
  }, [_c('SaleReportF2List')], 1)], 1) : _c('SaleReportList')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }