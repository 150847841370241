<template>
  <b-card
    id="sale-report-f2-filters"
    no-body
    class="mb-1"
  >
    <b-card-body class="py-1">
      <b-row>
        <!-- ANCHOR START DATE -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('topup.startDate')"
          >
            <flat-pickr
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :value.sync="startDateFilter"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                maxDate: endDateFilter ? endDateFilter : today,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="$emit('update:start-date-filter', $event)"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR END DATE -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('topup.endDate')"
          >
            <flat-pickr
              :value.sync="endDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                minDate: startDateFilter,
                maxDate: today,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="$emit('update:end-date-filter', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <!-- ANCHOR AGENCY CODE -->
          <b-form-group label-class="h5 py-0">
            <template #label>
              {{ $t('agency.agencyCode') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="agencyCode"
              :value.sync="agencyFilter"
              :options="agencyOptions"
              label="agencyCode"
              searchabled
              clearable
              :filterable="false"
              :loading="loading"
              :placeholder="$t('saleReport.phAgencyCode')"
              :reduce="val => val"
              @open="handleOpenAgency"
              @search="handleSearchAgency"
              @input="val => $emit('update:agency-filter', val)"
            >
              <template #selected-option="{ agencyName, agencyCode }">
                <div style="">
                  <span
                    class="d-block font-weight-bold text-truncate text-uppercase"
                  >
                    <span class="text-info">{{ agencyCode }}</span> <small>({{ agencyName }})</small>
                  </span>
                </div>
              </template>
              <template v-slot:option="{ agencyName, agencyCode }">
                <div style="">
                  <span
                    class="d-block font-weight-bold text-truncate text-uppercase"
                  >
                    <span class="">{{ agencyCode }}</span> <small>({{ agencyName }})</small>
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR BOOKING CODE -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.bookingCode')"
          >
            <b-form-input
              :value.sync="bookingCodeFilter"
              :placeholder="$t('saleReport.phBookingCode')"
              :formatter="trimUpperCaseInput"
              @input="$emit('update:booking-code-filter', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <!-- ANCHOR FLIGHT TYPE -->
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.flightType')"
          >
            <v-select
              :value.sync="flightTypeFilter"
              class="w-100"
              style="font-size: 1rem;"
              :options="flightTypeOptions"
              label="label"
              :clearable="false"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              @input="val => $emit('update:flight-type-filter', val)"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR NGÀY BAY -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.flightDate')"
          >
            <flat-pickr
              :value.sync="flightDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'd/m/Y',
                altFormat: 'd/m/Y',
                altInput: true,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="$emit('update:flight-date-filter', $event)"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR Source -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.source')"
          >
            <v-select
              :value.sync="sourceFilter"
              class="w-100"
              style="font-size: 1rem;"
              :options="distributorsList"
              label="label"
              clearable
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              @input="val => $emit('update:source-filter', val)"
            >
              <template #option="data">
                <span>{{ $te(`flight.airlines.${data.label}`) ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})` : data.label }}</span>
              </template>

              <template #selected-option="data">
                <span>{{ $te(`flight.airlines.${data.label}`) ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})` : data.label }}</span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR SaleReportTicketType -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.ticketType')"
          >
            <v-select
              :value.sync="saleReportTicketTypeFilter"
              class="w-100"
              style="font-size: 1rem;"
              :options="saleReportTicketTypeOptions"
              label="label"
              clearable
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              @input="val => $emit('update:sale-report-ticket-type-filter', val)"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR Airline -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.airline')"
          >
            <b-form-input
              :value.sync="airlineFilter"
              :placeholder="$t('saleReport.phAirline')"
              :formatter="trimUpperCaseInput"
              maxlength="2"
              @input="$emit('update:airline-filter', $event)"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <div class="d-flex align-items-center justify-content-end">
        <!-- ANCHOR Button Search -->
        <b-button
          variant="warning"
          class="mr-1"
          :disabled="!agencyFilter"
          @click="onUpdateFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </span>
        </b-button>

        <!-- ANCHOR Button Clear Search Filters -->
        <b-button
          variant="danger"
          @click="onClearFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import debounce from 'lodash/debounce'

import {
  flightTypeOptions,
} from '@/constants/saleReport'
import { distributorsList, saleReportTicketTypeOptions } from '@/constants/selectOptions'

import { trimUpperCaseInput } from '@core/comp-functions/forms/formatter-input'

import useSaleReportF2Handle from '@saleReport/sale-report-f2/useSaleReportF2Handle'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
  },
  props: {
    agencyFilter: {
      type: Object,
      default: () => {},
    },
    startDateFilter: {
      type: String,
      default: null,
    },
    endDateFilter: {
      type: String,
      default: null,
    },
    bookingCodeFilter: {
      type: String,
      default: null,
    },
    flightDateFilter: {
      type: String,
      default: null,
    },
    airlineFilter: {
      type: String,
      default: null,
    },
    sourceFilter: {
      type: String,
      default: null,
    },
    flightTypeFilter: {
      type: String,
      default: null,
    },
    saleReportTicketTypeFilter: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const onClearFilters = () => {
      emit('clear-filters')
    }

    const onUpdateFilters = () => {
      emit('fetch-sale-report-f2')
    }

    const { fetchAgencies, loading } = useSaleReportF2Handle()
    // ANCHOR fetch Agency
    const agencyOptionsDefault = ref([])
    const agencyOptions = ref([])
    function handleOpenAgency() {
      if (agencyOptionsDefault.value.length === 0) {
        fetchAgencies().then(res => {
          agencyOptions.value = res
          agencyOptionsDefault.value = res
        })
      } else {
        agencyOptions.value = agencyOptionsDefault.value
      }
    }

    const handleSearchAgency = debounce(search => {
      if (search) {
        fetchAgencies(search).then(res => {
          agencyOptions.value = res
        })
      }
    }, 300)

    return {
      // select Options
      flightTypeOptions,
      Vietnamese,

      today,
      distributorsList,
      onClearFilters,
      onUpdateFilters,
      trimUpperCaseInput,

      handleOpenAgency,
      loading,
      handleSearchAgency,
      agencyOptions,
      saleReportTicketTypeOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#sale-report-f2-filters ::v-deep {
  .form-group {
    margin-bottom: 2px;
  }
}
</style>
