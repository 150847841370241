var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SaleReportF2ListFilters', {
    ref: "saleReportF2ListFilters",
    attrs: {
      "agency-filter": _vm.agencyFilter,
      "start-date-filter": _vm.startDateFilter,
      "end-date-filter": _vm.endDateFilter,
      "booking-code-filter": _vm.bookingCodeFilter,
      "flight-date-filter": _vm.flightDateFilter,
      "airline-filter": _vm.airlineFilter,
      "source-filter": _vm.sourceFilter,
      "flight-type-filter": _vm.flightTypeFilter,
      "sale-report-ticket-type-filter": _vm.saleReportTicketTypeFilter
    },
    on: {
      "update:agencyFilter": function updateAgencyFilter($event) {
        _vm.agencyFilter = $event;
      },
      "update:agency-filter": function updateAgencyFilter($event) {
        _vm.agencyFilter = $event;
      },
      "update:startDateFilter": function updateStartDateFilter($event) {
        _vm.startDateFilter = $event;
      },
      "update:start-date-filter": function updateStartDateFilter($event) {
        _vm.startDateFilter = $event;
      },
      "update:endDateFilter": function updateEndDateFilter($event) {
        _vm.endDateFilter = $event;
      },
      "update:end-date-filter": function updateEndDateFilter($event) {
        _vm.endDateFilter = $event;
      },
      "update:bookingCodeFilter": function updateBookingCodeFilter($event) {
        _vm.bookingCodeFilter = $event;
      },
      "update:booking-code-filter": function updateBookingCodeFilter($event) {
        _vm.bookingCodeFilter = $event;
      },
      "update:flightDateFilter": function updateFlightDateFilter($event) {
        _vm.flightDateFilter = $event;
      },
      "update:flight-date-filter": function updateFlightDateFilter($event) {
        _vm.flightDateFilter = $event;
      },
      "update:airlineFilter": function updateAirlineFilter($event) {
        _vm.airlineFilter = $event;
      },
      "update:airline-filter": function updateAirlineFilter($event) {
        _vm.airlineFilter = $event;
      },
      "update:sourceFilter": function updateSourceFilter($event) {
        _vm.sourceFilter = $event;
      },
      "update:source-filter": function updateSourceFilter($event) {
        _vm.sourceFilter = $event;
      },
      "update:flightTypeFilter": function updateFlightTypeFilter($event) {
        _vm.flightTypeFilter = $event;
      },
      "update:flight-type-filter": function updateFlightTypeFilter($event) {
        _vm.flightTypeFilter = $event;
      },
      "update:saleReportTicketTypeFilter": function updateSaleReportTicketTypeFilter($event) {
        _vm.saleReportTicketTypeFilter = $event;
      },
      "update:sale-report-ticket-type-filter": function updateSaleReportTicketTypeFilter($event) {
        _vm.saleReportTicketTypeFilter = $event;
      },
      "fetch-sale-report-f2": _vm.fetchSaleReportF2,
      "clear-filters": _vm.clearFilter
    }
  }), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [!_vm.itemsSaleReportF2ListTable ? _c('div', [_c('BAlert', {
    staticClass: "px-1 py-1 my-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Chọn đại lý để xem ")])], 1) : _vm.itemsSaleReportF2ListTable && !_vm.itemsSaleReportF2ListTable.length ? _c('div', [_c('BAlert', {
    staticClass: "px-1 py-1 my-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Không tìm thấy báo cáo bán chi tiết của đại lý đã chọn ")])], 1) : _c('b-card', {
    staticStyle: {
      "max-height": "100vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "m-1"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _c('div', [_c('div', [_c('b-button', {
    staticClass: "px-75",
    attrs: {
      "variant": "flat-info"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "DownloadIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('export')) + " ")], 1)])], 1), _c('div', {
    staticClass: "d-block d-lg-none"
  }, [_c('b-dropdown', {
    staticClass: "m-lg-2",
    attrs: {
      "variant": "info",
      "boundary": "window",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MenuIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "pl-50"
        }, [_vm._v(_vm._s(_vm.$t('agency.moreDropdown.title')))])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.moreDropdown.export')) + " ")])], 1)], 1)])])]), _c('b-table', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "70vh",
      "margin-bottom": "0"
    },
    attrs: {
      "sticky-header": "",
      "items": _vm.itemsSaleReportF2ListTable,
      "responsive": "",
      "small": "",
      "bordered": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "table-class": "table-sales-report",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "sort-desc": _vm.isSortDirDesc,
      "no-border-collapse": ""
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-center"
          }, [column.key === 'checkbox' ? _c('div', {
            on: {
              "click": function click($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return _vm.onHandleSelectAllOrSelectNone();
              }
            }
          }, [_c('b-form-checkbox', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: !_vm.showCheckboxSelectAll,
              expression: "!showCheckboxSelectAll"
            }],
            attrs: {
              "indeterminate": true,
              "inline": ""
            }
          }), _c('b-form-checkbox', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.showCheckboxSelectAll,
              expression: "showCheckboxSelectAll"
            }],
            attrs: {
              "inline": "",
              "checked": _vm.isSelectAll,
              "disabled": _vm.isAlreadySelectAll
            }
          })], 1) : [_c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.window",
              modifiers: {
                "hover": true,
                "window": true
              }
            }],
            staticClass: "text-truncate",
            attrs: {
              "title": _vm.$t("saleReport.columns.".concat(data.label))
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("saleReport.columns.shortTitle.".concat(data.label))) + " ")])]], 2)];
        }
      };
    }), _vm._l(_vm.tableColumns, function (column) {
      return {
        key: "cell(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            key: column.key,
            staticClass: "text-nowrap",
            class: {
              'text-right': typeof item[column.key] === 'number'
            }
          }, [_vm._v(" " + _vm._s(item[column.key]) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap d-flex-center"
        }, [_vm._v(" " + _vm._s(data.index + _vm.dataMeta.from) + " ")])];
      }
    }, {
      key: "cell(source)",
      fn: function fn(_ref2) {
        var source = _ref2.item.source;
        return [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(source)) ? _vm.$t("flight.sourceName.".concat(source)) : source) + " ")];
      }
    }, {
      key: "cell(issueDate)",
      fn: function fn(_ref3) {
        var issueDate = _ref3.item.issueDate;
        return [_vm._v(" " + _vm._s(_vm.replaceText(_vm.convertISODateTime(issueDate).dateTime)) + " ")];
      }
    }, {
      key: "cell(flightType)",
      fn: function fn(_ref4) {
        var flightType = _ref4.item.flightType;
        return [_vm._v(" " + _vm._s(_vm.$te("saleReport.".concat(flightType)) ? _vm.$t("saleReport.".concat(flightType)) : flightType) + " ")];
      }
    }, {
      key: "cell(trips)",
      fn: function fn(_ref5) {
        var trips = _ref5.item.trips;
        return [trips ? _c('div', _vm._l(_vm.convertShortenTrips(trips), function (trip, tripIndex) {
          return _c('div', {
            key: tripIndex
          }, [_vm._v(" " + _vm._s(trip) + " ")]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell(flightDates)",
      fn: function fn(_ref6) {
        var flightDates = _ref6.item.flightDates;
        return [_vm._v(" " + _vm._s(_vm.replaceText(flightDates)) + " ")];
      }
    }, {
      key: "cell(fareBasisCode)",
      fn: function fn(_ref7) {
        var fareBasisCode = _ref7.item.fareBasisCode;
        return [_vm._v(" " + _vm._s(_vm.replaceText(fareBasisCode)) + " ")];
      }
    }, {
      key: "cell(bookingDate)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.replaceText(_vm.convertISODateTime(item.bookingDate).dateTime)) + " ")])];
      }
    }, {
      key: "cell(ticketType)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.$te("saleReport.".concat(item.ticketType)) ? _vm.$t("saleReport.".concat(item.ticketType)) : item.ticketType) + " ")])];
      }
    }, {
      key: "cell(balanceAdjustment)",
      fn: function fn(_ref10) {
        var balanceAdjustment = _ref10.item.balanceAdjustment;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(balanceAdjustment)) + " ")])];
      }
    }, {
      key: "cell(priceTicket)",
      fn: function fn(_ref11) {
        var priceTicket = _ref11.item.priceTicket;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(priceTicket)) + " ")])];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(_ref12) {
        var collectionFee = _ref12.item.collectionFee;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(collectionFee)) + " ")])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.tax)) + " ")])];
      }
    }, {
      key: "cell(otherTax)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.otherTax)) + " ")])];
      }
    }, {
      key: "cell(fee)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.fee)) + " ")])];
      }
    }, {
      key: "cell(feeIn)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.feeOut)) + " ")])];
      }
    }, {
      key: "cell(feeOut)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.feeIn)) + " ")])];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.feeService)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaid)",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commissionPrepaid)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaidIn)",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commissionPrepaidIn)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaidOut)",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commissionPrepaidOut)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommission)",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.unpaidCommission)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommissionIn)",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.unpaidCommissionIn)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommissionOut)",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.unpaidCommissionOut)) + " ")])];
      }
    }, {
      key: "cell(commission)",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commission)) + " ")])];
      }
    }, {
      key: "cell(tradeCreditors)",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.tradeCreditors)) + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.receivables)) + " ")])];
      }
    }, {
      key: "cell(profit)",
      fn: function fn(_ref28) {
        var item = _ref28.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.profit)) + " ")])];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref29) {
        var totalPayment = _ref29.item.totalPayment;
        return [_c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(totalPayment)) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.replaceText(_vm.dateTime(data.item.createdAt))) + " ")])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.replaceText(_vm.dateTime(data.item.updatedAt))) + " ")])];
      }
    }, {
      key: "cell(agency)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.agency.agencyCode.toUpperCase()) + " ")])];
      }
    }, {
      key: "cell(booker)",
      fn: function fn(data) {
        return [data.item.booker ? _c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.booker.username.toUpperCase()) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(issuer)",
      fn: function fn(data) {
        return [data.item.issuer ? _c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.issuer.username.toUpperCase()) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [data.item.isManual ? _c('b-button', {
          staticClass: "px-1 py-50",
          attrs: {
            "variant": "flat-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(data.item.id);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "TrashIcon",
            "size": "16"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "bottom-row",
      fn: function fn(data) {
        return _vm._l(data.fields, function (field, i) {
          return _c('td', {
            key: i,
            staticClass: "text-right",
            staticStyle: {
              "font-size": "18px"
            }
          }, [_vm.visibleBottomRow.includes(field.key) ? _c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalColumn(field.key)) || 0) + " ")]) : _vm._e(), field.key === 'no' ? _c('div', {
            staticClass: "text-uppercase"
          }, [_vm._v(" " + _vm._s(_vm.$t('saleReport.sum')) + " ")]) : _vm._e()]);
        });
      }
    }], null, true)
  }), _c('div', {
    staticClass: "mx-2 my-50"
  }, [_c('b-row', {
    staticClass: "d-flex-center justify-content-md-between"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-25 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalItems,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }