<template>
  <div>
    <!-- ANCHOR Filters -->
    <SaleReportF2ListFilters
      ref="saleReportF2ListFilters"
      :agency-filter.sync="agencyFilter"
      :start-date-filter.sync="startDateFilter"
      :end-date-filter.sync="endDateFilter"
      :booking-code-filter.sync="bookingCodeFilter"
      :flight-date-filter.sync="flightDateFilter"
      :airline-filter.sync="airlineFilter"
      :source-filter.sync="sourceFilter"
      :flight-type-filter.sync="flightTypeFilter"
      :sale-report-ticket-type-filter.sync="saleReportTicketTypeFilter"
      @fetch-sale-report-f2="fetchSaleReportF2"
      @clear-filters="clearFilter"
    />
    <b-overlay
      :show="loading"
      rounded="sm"
      no-fade
      variant="transparent"
      :opacity="0.5"
    >
      <div v-if="!itemsSaleReportF2ListTable">
        <BAlert
          show
          variant="warning"
          class="px-1 py-1 my-1"
        >
          Chọn đại lý để xem
        </BAlert>
      </div>

      <div v-else-if="itemsSaleReportF2ListTable && !itemsSaleReportF2ListTable.length">
        <BAlert
          show
          variant="warning"
          class="px-1 py-1 my-1"
        >
          Không tìm thấy báo cáo bán chi tiết của đại lý đã chọn
        </BAlert>
      </div>

      <b-card
        v-else
        style="max-height: 100vh"
        class=""
        no-body
      >
        <!-- SECTION Table Top -->
        <div class="m-1">
          <div class="d-flex justify-content-between">
            <!-- ANCHOR Per Page -->
            <div
              md="auto"
              class="px-0 mr-md-1"
            >
              <v-select
                v-model="sizePerPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sizePerPageLgOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              >
                <template #option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </div>

            <!-- ANCHOR Button -->
            <div>
              <div>
                <b-button
                  variant="flat-info"
                  class="px-75"
                  @click="confirmExport()"
                >
                  <span class="text-nowrap">
                    <feather-icon
                      icon="DownloadIcon"
                      size="16"
                      class="cursor-pointer"
                    />
                    {{ $t('export') }}
                  </span>
                </b-button>
              </div>

              <!-- Dropdown -->
              <div class="d-block d-lg-none">
                <b-dropdown
                  variant="info"
                  class="m-lg-2"
                  boundary="window"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MenuIcon"
                      size="16"
                    />
                    <span class="pl-50">{{ $t('agency.moreDropdown.title') }}</span>
                  </template>
                  <b-dropdown-item
                    @click="confirmExport()"
                  >
                    {{ $t('agency.moreDropdown.export') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <!-- !SECTION -->

        <!-- SECTION Table content -->
        <b-table
          style="max-height: 70vh; margin-bottom: 0;"
          sticky-header
          class="position-relative"
          :items="itemsSaleReportF2ListTable"
          responsive
          small
          bordered
          :fields="tableColumns"
          primary-key="id"
          table-class="table-sales-report"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('noMatchingResult')"
          :sort-desc.sync="isSortDirDesc"
          no-border-collapse
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-center"
            >
              <!-- ANCHOR Checkbox Sellect All -->
              <div
                v-if="column.key === 'checkbox'"
                @click.prevent.stop="onHandleSelectAllOrSelectNone()"
              >
                <b-form-checkbox
                  v-show="!showCheckboxSelectAll"
                  :indeterminate="true"
                  inline
                />
                <b-form-checkbox
                  v-show="showCheckboxSelectAll"
                  inline
                  :checked="isSelectAll"
                  :disabled="isAlreadySelectAll"
                />
              </div>
              <template v-else>
                <span
                  v-b-tooltip.hover.window
                  class="text-truncate"
                  :title="$t(`saleReport.columns.${data.label}`)"
                >
                  {{ $t(`saleReport.columns.shortTitle.${data.label}`) }}
                </span>
              </template>
            </div>
          </template>

          <template
            v-for="column in tableColumns"
            v-slot:[`cell(${column.key})`]="{ item }"
          >
            <!-- item {{ item[column.key] }} -->
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR no. -->
          <template #cell(no)="data">
            <div class="mb-0 text-nowrap d-flex-center">
              {{ data.index + dataMeta.from }}
            </div>
          </template>

          <!-- ANCHOR source -->
          <template #cell(source)="{ item: { source } }">
            {{ $te(`flight.sourceName.${source}`) ? $t(`flight.sourceName.${source}`) : source }}
          </template>

          <!-- ANCHOR issueDate -->
          <template #cell(issueDate)="{ item: { issueDate } }">
            {{ replaceText(convertISODateTime(issueDate).dateTime) }}
          </template>

          <!-- ANCHOR flightType -->
          <template #cell(flightType)="{ item: { flightType } }">
            {{ $te(`saleReport.${flightType}`) ? $t(`saleReport.${flightType}`) : flightType }}
          </template>

          <!-- ANCHOR trips -->
          <template #cell(trips)="{ item: { trips } }">
            <div v-if="trips">
              <div
                v-for="(trip, tripIndex) in convertShortenTrips(trips)"
                :key="tripIndex"
              >
                {{ trip }}
              </div>
            </div>
          </template>

          <!-- ANCHOR flightDates -->
          <template #cell(flightDates)="{ item: { flightDates } }">
            {{ replaceText(flightDates) }}
          </template>

          <!-- ANCHOR fareBasisCode -->
          <template #cell(fareBasisCode)="{ item: { fareBasisCode } }">
            {{ replaceText(fareBasisCode) }}
          </template>

          <!-- ANCHOR bookingDate -->
          <template #cell(bookingDate)="{ item }">
            <div>
              {{ replaceText(convertISODateTime(item.bookingDate).dateTime) }}
            </div>
          </template>

          <!-- ANCHOR ticketType -->
          <template #cell(ticketType)="{ item }">
            <div>
              {{ $te(`saleReport.${item.ticketType}`) ? $t(`saleReport.${item.ticketType}`) : item.ticketType }}
            </div>
          </template>

          <!-- ANCHOR balanceAdjustment -->
          <template #cell(balanceAdjustment)="{ item: { balanceAdjustment} }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(balanceAdjustment) }}
            </div>
          </template>

          <!-- ANCHOR priceTicket -->
          <template #cell(priceTicket)="{ item: { priceTicket } }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(priceTicket) }}
            </div>
          </template>

          <!-- ANCHOR collection fee -->
          <template #cell(collectionFee)="{ item: { collectionFee } }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(collectionFee) }}
            </div>
          </template>

          <!-- ANCHOR tax -->
          <template #cell(tax)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.tax) }}
            </div>
          </template>

          <!-- ANCHOR otherTax -->
          <template #cell(otherTax)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.otherTax) }}
            </div>
          </template>

          <!-- ANCHOR fee -->
          <template #cell(fee)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.fee) }}
            </div>
          </template>

          <!-- ANCHOR feeIn -->
          <template #cell(feeIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.feeOut) }}
            </div>
          </template>

          <!-- ANCHOR feeOut -->
          <template #cell(feeOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.feeIn) }}
            </div>
          </template>

          <!-- ANCHOR feeService -->
          <template #cell(feeService)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.feeService) }}
            </div>
          </template>

          <!-- ANCHOR commissionPrepaid -->
          <template #cell(commissionPrepaid)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaid) }}
            </div>
          </template>
          <!-- ANCHOR commissionPrepaidIn -->
          <template #cell(commissionPrepaidIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaidIn) }}
            </div>
          </template>
          <!-- ANCHOR commissionPrepaidOut -->
          <template #cell(commissionPrepaidOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaidOut) }}
            </div>
          </template>

          <!-- ANCHOR unpaidCommission -->
          <template #cell(unpaidCommission)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.unpaidCommission) }}
            </div>
          </template>

          <!-- ANCHOR unpaidCommissionIn -->
          <template #cell(unpaidCommissionIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.unpaidCommissionIn) }}
            </div>
          </template>

          <!-- ANCHOR unpaidCommissionOut -->
          <template #cell(unpaidCommissionOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.unpaidCommissionOut) }}
            </div>
          </template>

          <!-- ANCHOR commission -->
          <template #cell(commission)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commission) }}
            </div>
          </template>

          <!-- ANCHOR tradeCreditors -->
          <template #cell(tradeCreditors)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.tradeCreditors) }}
            </div>
          </template>

          <!-- ANCHOR receivables -->
          <template #cell(receivables)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.receivables) }}
            </div>
          </template>

          <!-- ANCHOR profit: Lợi nhuận -->
          <template #cell(profit)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.profit) }}
            </div>
          </template>

          <!-- ANCHOR totalPayment -->
          <template #cell(totalPayment)="{ item: { totalPayment } }">
            <div class="font-weight-bold text-right">
              {{ formatVnCurrency(totalPayment) }}
            </div>
          </template>

          <!-- ANCHOR createdAt -->
          <template #cell(createdAt)="data">
            <div class="mb-0">
              {{ replaceText(dateTime(data.item.createdAt)) }}
            </div>
          </template>

          <!-- ANCHOR updatedAt -->
          <template #cell(updatedAt)="data">
            <div class="mb-0">
              {{ replaceText(dateTime(data.item.updatedAt)) }}
            </div>
          </template>

          <!-- ANCHOR agency -->
          <template #cell(agency)="data">
            <div class="mb-0 text-nowrap">
              {{ data.item.agency.agencyCode.toUpperCase() }}
            </div>
          </template>

          <!-- ANCHOR booker -->
          <template #cell(booker)="data">
            <div
              v-if="data.item.booker"
              class="mb-0 text-nowrap"
            >
              {{ data.item.booker.username.toUpperCase() }}
            </div>
          </template>

          <!-- ANCHOR issuer -->
          <template #cell(issuer)="data">
            <div
              v-if="data.item.issuer"
              class="mb-0 text-nowrap"
            >
              {{ data.item.issuer.username.toUpperCase() }}
            </div>
          </template>

          <!-- ANCHOR actions -->
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.isManual"
              variant="flat-danger"
              class="px-1 py-50"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                style="cursor: pointer"
              />
            </b-button>
          </template>
          <template
            slot="bottom-row"
            slot-scope="data"
          >
            <td
              v-for="(field, i) in data.fields"
              :key="i"
              class="text-right"
              style="font-size: 18px"
            >
              <div v-if="visibleBottomRow.includes(field.key)">
                {{ formatCurrency(totalColumn(field.key)) || 0 }}
              </div>
              <div
                v-if="field.key === 'no'"
                class="text-uppercase"
              >
                {{ $t('saleReport.sum') }}
              </div>
            </td>
          </template>
        </b-table>

        <!-- !SECTION -->

        <!-- ANCHOR: Table Footer -->
        <div class="mx-2 my-50">
          <b-row class="d-flex-center justify-content-md-between">
            <b-col
              cols="12"
              md="auto"
              class="d-flex align-items-center justify-content-center"
            >
              <span class="text-muted">
                {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
                {{ dataMeta.of }} {{ $t('paginationText.items') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="auto"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="sizePerPage"
                first-number
                last-number
                class="mb-0 mt-25 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BOverlay,
  BTable,
  BPagination, BAlert, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import { SALE_REPORT_TABLE_COLUMNS } from '@/constants/saleReport'
import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'

import {
  formatVnCurrency,
  dateTime,
  getDate,
  convertISODateTime,
  formatCurrency,
  replaceText,
} from '@core/utils/filter'

import { convertShortenTrips } from '@saleReport/useSaleReportHandle'
import useSaleReportF2Handle from '@saleReport/sale-report-f2/useSaleReportF2Handle'
import saleReportF2StoreModule from '@saleReport/sale-report-f2/saleReportF2StoreModule'

import SaleReportF2ListFilters from './SaleReportF2ListFilters.vue'

export default {
  components: {
    SaleReportF2ListFilters,

    BCard,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BOverlay,
    BTable,
    BPagination,
    BAlert,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const SALE_REPORT_F2_APP_STORE_MODULE_NAME = 'app-sale-report-f2'

    // Register module
    if (!store.hasModule(SALE_REPORT_F2_APP_STORE_MODULE_NAME)) {
      store.registerModule(SALE_REPORT_F2_APP_STORE_MODULE_NAME, saleReportF2StoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALE_REPORT_F2_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(SALE_REPORT_F2_APP_STORE_MODULE_NAME)
      }
    })

    const tableColumns = ref([])
    tableColumns.value = SALE_REPORT_TABLE_COLUMNS.filter(item => !['actions'].includes(item.key))

    const {
      clearFilter, sizePerPage, itemsSaleReportF2ListTable,
      currentPage,
      dataMeta,
      sortBy, loading,
      isSortDirDesc, totalItems, agencyFilter, fetchSaleReportF2,
      startDateFilter,
      endDateFilter,
      bookingCodeFilter,
      flightDateFilter,
      airlineFilter,
      sourceFilter,
      flightTypeFilter,
      saleReportTicketTypeFilter,
      exportSaleReportsF2,
    } = useSaleReportF2Handle()

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            exportSaleReportsF2()
          }
        })
    }

    const visibleBottomRow = [
      'priceTicket', 'tax', 'otherTax',
      'collectionFee', 'feeIn', 'feeOut',
      'feeService', 'commission', 'commissionPrepaidIn',
      'commissionPrepaidOut', 'unpaidCommissionIn', 'unpaidCommissionOut', 'commission',
      'tradeCreditors', 'receivables', 'profit', 'balanceAdjustment',
    ]

    function totalColumn(key) {
      let keyTemp = key
      if (keyTemp === 'feeIn') keyTemp = 'feeOut'
      else if (keyTemp === 'feeOut') keyTemp = 'feeIn'
      return itemsSaleReportF2ListTable.value.reduce((sum, item) => sum + item[keyTemp], 0)
    }

    return {
      fetchSaleReportF2,
      clearFilter,
      sizePerPage,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      totalItems,
      itemsSaleReportF2ListTable,
      loading,
      agencyFilter,
      startDateFilter,
      endDateFilter,
      bookingCodeFilter,
      flightDateFilter,
      airlineFilter,
      sourceFilter,
      flightTypeFilter,
      saleReportTicketTypeFilter,

      tableColumns,
      dateTime,
      getDate,
      convertISODateTime,
      sizePerPageLgOptions,
      formatVnCurrency,
      confirmExport,

      visibleBottomRow,
      totalColumn,
      formatCurrency,
      replaceText,
      convertShortenTrips,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.table-sales-report {
  th {
    padding-left: 6px;
    padding-right: 6px;
  }

  td {
    font-size: 14px;
    padding: 6px !important;
  }
}
</style>
