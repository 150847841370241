import { ref, computed, watch } from '@vue/composition-api'

import { apiAgencies } from '@/api'
// import { useToast } from 'vue-toastification/composition'
import { convertISODateTime } from '@/@core/utils/filter'
import store from '@/store'

import useToast from '@useToast'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSaleReportF2Handle() {
  // eslint-disable-next-line no-unused-vars
  const { toastSuccess, toastError } = useToast()

  // Loading
  const loading = ref(null)
  // Refs
  const refSaleReportF2ListTable = ref(null)
  const itemsSaleReportF2ListTable = ref()

  const agencyFilter = ref(null)
  const startDateFilter = ref(convertISODateTime(new Date()).dateFilter)
  const endDateFilter = ref(convertISODateTime(new Date()).dateFilter)
  const bookingCodeFilter = ref()
  const flightDateFilter = ref()
  const airlineFilter = ref()
  const sourceFilter = ref()
  const flightTypeFilter = ref()
  const saleReportTicketTypeFilter = ref()

  // Filter
  const sizePerPage = ref(40)
  const currentPage = ref(1)
  const sortBy = ref() // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const totalItems = ref(0)
  const dataMeta = computed(() => {
    const result = {
      from: sizePerPage.value * (currentPage.value - 1) + 1,
      to: sizePerPage.value * (currentPage.value - 1) + (totalItems.value % sizePerPage.value),
      of: totalItems.value,
    }
    return result
  })

  const clearFilter = () => {
    agencyFilter.value = null
    startDateFilter.value = convertISODateTime(new Date()).dateFilter
    endDateFilter.value = convertISODateTime(new Date()).dateFilter
    bookingCodeFilter.value = null
    flightDateFilter.value = null
    airlineFilter.value = null
    sourceFilter.value = null
    flightTypeFilter.value = null
    itemsSaleReportF2ListTable.value = null
    saleReportTicketTypeFilter.value = null
  }

  const fetchSaleReportF2 = () => new Promise((resolve, reject) => {
    loading.value = true
    const params = {
      size: sizePerPage.value,
      page: currentPage.value,
      sortBy: sortBy.value ? sortBy.value : 'issueDate',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      startDate: startDateFilter.value || undefined,
      endDate: endDateFilter.value || undefined,
      bookingCode: bookingCodeFilter.value || undefined,
      flightDate: flightDateFilter.value || undefined,
      airline: airlineFilter.value || undefined,
      source: sourceFilter.value || undefined,
      flightType: flightTypeFilter.value || undefined,
      saleReportTicketType: saleReportTicketTypeFilter.value || undefined,
    }
    store.dispatch('app-sale-report-f2/fetchSaleReportsF2', {
      agencyId: agencyFilter.value?.id,
      params,
    })
      .then(response => {
        const { data } = response
        totalItems.value = data.total
        itemsSaleReportF2ListTable.value = data.items
        resolve(data.items)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.saleReport.fetchListError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const exportSaleReportsF2 = () => {
    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      sortBy: sortBy.value ? sortBy.value : 'issueDate',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      startDate: startDateFilter.value || undefined,
      endDate: endDateFilter.value || undefined,
      bookingCode: bookingCodeFilter.value || undefined,
      flightDate: flightDateFilter.value || undefined,
      airline: airlineFilter.value || undefined,
      source: sourceFilter.value || undefined,
      flightType: flightTypeFilter.value || undefined,
    }
    store
      .dispatch('app-sale-report-f2/exportSaleReportsF2', {
        agencyId: agencyFilter.value?.id,
        payload,
      })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.exportError',
        })
      })
  }

  const fetchAgencies = (searchText = undefined) => new Promise((resolve, reject) => {
    loading.value = true
    apiAgencies
      .fetchAgencies({ searchText })
      .then(response => { resolve(response.data.items) })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  watch([currentPage, sizePerPage, sortBy, isSortDirDesc], () => {
    fetchSaleReportF2()
  })

  return {
    // Loading
    loading,
    // Refs
    refSaleReportF2ListTable,

    // Filters
    sizePerPage,
    currentPage,
    dataMeta,
    sortBy,
    isSortDirDesc,

    // Extra Filters
    totalItems,
    clearFilter,

    // for f2
    fetchSaleReportF2,
    fetchAgencies,
    itemsSaleReportF2ListTable,
    agencyFilter,
    startDateFilter,
    endDateFilter,
    bookingCodeFilter,
    flightDateFilter,
    airlineFilter,
    sourceFilter,
    flightTypeFilter,
    saleReportTicketTypeFilter,
    exportSaleReportsF2,
  }
}
